/*
* 共通スクリプト
*/

/*
* ajaxアクセス用
*/
$.ajaxSetup({
  headers: {
    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
  }
});


$(function() {

  /*
  * レビュー続きを見る
  */
  $('.review-text').each(function(index) {
    let elementHeight = $(this).height();
    let lineHeight = parseFloat($(this).css('line-height'));
    if (elementHeight > (lineHeight * 3)) {
      $(this).css({
        'height': (lineHeight * 3),
        'overflow': 'hidden',
        }
      );
      $(this).css('height', (lineHeight * 3));
      $(this).next('.readmore-btn').show();
    }
  });
  $(document).on('click', '.readmore-btn', function(event) {
    event.preventDefault();
    $(this).hide();
    $(this).prev('.review-text').css('height', 'auto');
  });

  /*
  var textHeight = $('.review-text').height();
  var lineHeight = parseFloat($('.review-text').css('line-height'));
  var lineNum = 3;
  var textNewHeight = lineHeight * lineNum;

  if (textHeight > textNewHeight) {
    $('.review-text').css({
        'height': textNewHeight,
        'overflow':'hidden'
    });
    $('.readmore-btn').show();
    $('.readmore-btn').click(function() {
      $(this).hide();
      $(this).closest('.review-text-wrapper').find('.review-text').css({
        'height': textHeight,
        'overflow': 'visible'
      });
      return false;
    });
  };
*/

  // 管理画面

  /*
  * データ削除確認
  */
  $('body').on('submit', '.formDelete', function() {
    if(window.confirm('削除してよろしいですか？')) {
      return true;
    }
    else {
      return false;
    }
  });

});
